<template>
  <div>
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >预约报表，按商户维度统计各渠道预约停车情况；适用于运营人员和财务人员了解各个商户预约停车情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./Report/img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="searchWrapper">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Merchant_Name')">
              <!-- <el-input v-model="formInline.user"></el-input> -->
              <el-select v-model.trim="formInline.operationId" filterable size="15">
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="value in tenantList"
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="$t('searchModule.date')">
        <el-date-picker
          v-model="searchDate"
          @change="editData"
          type="daterange"
          value-format='yyyy-MM-dd'
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item> -->
            <el-form-item :label="$t('searchModule.Date_search')" label-width="90px">
              <a-date-picker
                ref="datePicker"
                :YearShowHidden="selectkeys[3]"
                :selectkeys="selectkeys"
                :previousDate="previousDate"
                :nextDate="nextDate"
              ></a-date-picker>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button type="primary" icon="el-icon-search" @click="getData">{{ $t('button.search') }}</el-button>
            <el-button icon="el-icon-delete" @click="clearVal">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button icon="el-icon-upload2" @click="exportFile">{{ $t('button.export') }}</el-button>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tableWrapper">
      <!-- 筛选按钮 -->
      <div class="switch">
        <screen
            :checkBoxGroup="colData"
            :checkedColumns="checkedColumns"
            :reportType="8"
            @selectChange="selectChange"
          ></screen>
      </div>
      <el-table
        :data="tableData"
        header-cell-class-name="headerCallStyle"
        :cell-style="callStyle"
        :key="reload"
        class="paddingB20 graphShadow"
        style="width: 100%"
      >
        <el-table-column
          label=""
          v-if="colData[0].isTrue || colData[1].isTrue"
          min-width="150"
          class-name="headerCellQ cell-wrapper"
        >
          <el-table-column
            v-if="colData[0].isTrue"
            prop="operationName"
            align="center"
            :label="$t('searchModule.Merchant')"
            min-width="150"
            class-name="title-wrapper"
          ></el-table-column>
          <el-table-column
            v-if="colData[1].isTrue"
            prop="appointmentNum"
            align="center"
            label="预约数"
            min-width="150"
            class-name="title-wrapper"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          label="预约支付金额"
          align="center"
          class-name="headerCellQ headerSize"
          v-if="colData[2].isTrue || colData[3].isTrue || colData[4].isTrue || colData[5].isTrue"
        >
          <el-table-column label="微信" align="center" v-if="colData[2].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.wechatPayMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="支付宝" align="center" v-if="colData[3].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.alipayPayMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column
            label="招商银行"
            align="center"
            v-if="colData[4].isTrue"
            :min-width="150"
          >
            <div slot-scope="scope">
              <span>{{ scope.row.cmbPayMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="小计" align="center" v-if="colData[5].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.totalPayMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="预约退款金额"
          align="center"
          class-name="headerCellW headerSize headerCellQ"
          v-if="
            colData[6].isTrue ||
            colData[7].isTrue ||
            colData[8].isTrue ||
            colData[9].isTrue ||
            colData[10].isTrue
          "
        >
          <el-table-column label="微信" align="center" v-if="colData[6].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.wechatRefundMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="支付宝" align="center" v-if="colData[7].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.alipayRefundMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column
            label="招商银行"
            align="center"
            v-if="colData[8].isTrue"
            :min-width="150"
          >
            <div slot-scope="scope">
              <span>{{ scope.row.cmbRefundMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="余额" align="center" v-if="colData[9].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.balanceRefundMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="小计" align="center" v-if="colData[10].isTrue" :min-width="150">
            <div slot-scope="scope">
              <span>{{ scope.row.totalRefundMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="小计"
          align="center"
          class-name="headerCellE headerSize"
          v-if="
            colData[11].isTrue ||
            colData[12].isTrue ||
            colData[13].isTrue ||
            colData[14].isTrue ||
            colData[15].isTrue
          "
        >
          <el-table-column label="微信" align="center" v-if="colData[11].isTrue">
            <div slot-scope="scope">
              <span>{{ scope.row.wechatTotalMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="支付宝" align="center" v-if="colData[12].isTrue">
            <div slot-scope="scope">
              <span>{{ scope.row.alipayTotalMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column
            label="招商银行"
            align="center"
            v-if="colData[13].isTrue"
            min-width="100"
          >
            <div slot-scope="scope">
              <span>{{ scope.row.cmbTotalMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column label="余额" align="center" v-if="colData[14].isTrue">
            <div slot-scope="scope">
              <span>{{ scope.row.balanceTotalMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
          <el-table-column
            prop="totalMoney"
            align="center"
            label="小计"
            v-if="colData[15].isTrue"
            min-width="150"
          >
            <div slot-scope="scope">
              <span>{{ scope.row.totalMoney | moneyHandling() }}</span>
            </div>
          </el-table-column>
        </el-table-column>
        <div class="pagerWrapper bgFFF">
          <el-pagination
            style="margin-top: 20px"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="totalpage"
          >
          </el-pagination>
        </div>
      </el-table>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
import screen from "@/components/screen/index";
export default {
  components: {
    screen
  },
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
    return {
      closeShow: true,
      screeningNum: 0,
      reload: true,
      previousDate: new Date(),
      nextDate: new Date(),
      // colData中列出表格中的每一列，默认都展示
      colData: [
        { label: this.$t("list.Merchant"), isTrue: false },
        { label: "预约数", isTrue: false },
        { label: "预约支付金额-微信", isTrue: false },
        { label: "预约支付金额-支付宝", isTrue: false },
        { label: "预约支付金额-招商银行", isTrue: false },
        { label: "预约支付金额-小计", isTrue: false },
        { label: "预约退款金额-微信", isTrue: false },
        { label: "预约退款金额-支付宝", isTrue: false },
        { label: "预约退款金额-招商银行", isTrue: false },
        { label: "预约退款金额-余额", isTrue: false },
        { label: "预约退款金额-小计", isTrue: false },
        { label: "小计-微信", isTrue: false },
        { label: "小计-支付宝", isTrue: false },
        { label: "小计-招商银行", isTrue: false },
        { label: "小计-余额", isTrue: false },
        { label: "小计-小计", isTrue: false },
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      selectkeys: ["date", "month", "week", "year"],
      searchDate: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      formInline: {
        operationId: "",
        startTime: dateFormat(start, "yyyy-MM-dd"),
        endTime: dateFormat(end, "yyyy-MM-dd"),
      },
      tenantList: [],
      page: 1,
      pageSize: 15,
      totalpage: 1,
      tableData: [],
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.colData.filter((i, index) => {
        i.isTrue = false;
      });
      selectList.forEach((i) => {
        this.colData[i].isTrue = true;
      });
      this.getData();
    },
    // 获取筛选项
    getReportFieldHide() {
      // 报表类型，1=临停报表；2=运营报表；3=停车卡报表；4=预约报表；5=营收报表；6=渠道统计；7=PDA统计
      // 8=预约报表-商户维度
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: "8",
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.colData[i].isTrue = true
          });
        });
    },
    // table 单元格样式动态设置
    callStyle(row) {
      return {
        "border-right": 0,
      };
    },
    getNowFormatDate() {
      let date = new Date();
      /* let seperator1 = "-";
        let seperator2 = ":"; */
      let seperator1 = "";
      let seperator2 = "";
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let hours = date.getHours();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      let minutes = date.getMinutes();
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      let currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        "" +
        hours +
        seperator2 +
        minutes +
        seperator2 +
        seconds;
      return currentdate;
    },
    exportFile() {
      let obj = {
        fileName: "预约停车商户报表" + this.getNowFormatDate(),
        operationId: this.formInline.operationId,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
      };
      exportExcelNew("/acb/2.0/parkAppointmentStatics/operation/exportlist", obj);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    editData(val) {
      this.formInline.startTime = val[0];
      this.formInline.endTime = val[1];
    },
    clearVal() {
      const start = new Date();
      const end = new Date(new Date().getTime());
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
      this.searchDate = [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")];
      this.formInline.operationId = "";
      this.$refs.datePicker.clear();
      // this.getData()
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        // console.log('res-->', res);
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getData() {
      this.formInline.startTime = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      this.formInline.endTime = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      this.$axios
        .get("/acb/2.0/parkAppointmentStatics/operation/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            ...this.formInline,
          },
        })
        .then((res) => {
          this.totalpage = parseInt(res.value.total);
          this.tableData = res.value.list;
        });
    },
  },
  mounted() {
    this.getData();
    this.getTenantList();
    this.getReportFieldHide();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.info
  margin: 0 -20px 0 -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);
  .info_content
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  img
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
.demo-form-inline{
  padding: 22px 22px 0 22px;
  margin: 20px 0;
  background: #fff;
}
.pagerWrapper
  text-align right
  margin-top 28px;
  font-size 12px

.tableWrapper >>> .headerCallStyle {
  // height: 30px;
  font-size: 14px;
  background: #F3F7FF!important;
  padding: 5px;
  border-right: 0
  font-weight: 600
  color: rgba(0, 0, 0, 0.85)
}
.tableWrapper  >>> .is-leaf {
  height: 54px;
}
.tableWrapper >>> .headerCellQ {
  background: #ffffff!important;
}
.tableWrapper >>> th.el-table__cell {
  border-right: 1px solid #CFDAEF;
}
.tableWrapper >>> .headerCellE {
  background: #ffffff!important;
}
.tableWrapper  >>> .headerSize {
  height: 30px;
  color: rgba(82, 96, 151, 1);
  font-size: 14px;
}
.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
    background: transparent;
    border-radius: 10px;
  }

  // 滚动条的滑块样式
  .tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(217, 225, 238, 1);
    border-radius: 10px;
  }
.tableWrapper {
  margin-top: 16px;
  position: relative;
  .switch {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 39px;
    cursor: pointer;
    img {
      height: 54px;
      width: 40px;
    }
  }
}

.tableWrapper >>> .el-table {
  width: 100%!important;
}
</style>
